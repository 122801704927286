@media (max-width: 728px) {
  .align-center-mobile {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .none600 {
    display: none;
  }
}

@media (max-width: 400px) {
  .rccs {
    width: 100%;
  }
  .rccs__card {
    width: 100%;
  }
}

@media (min-width: 728px) {
  .mrd26 {
    margin-right: 26px;
  }
}

.flex-center {
  justify-content: center;
  display: flex;
}

.align-center {
  display: flex;
  align-items: center;
}

.column {
  flex-direction: column;
}

.uppercase {
  text-transform: uppercase;
}

.hidden {
  visibility: hidden;
}

.text-center {
  text-align: center;
}

.relative {
  position: relative;
}

